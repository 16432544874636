$(document).ready(function(){
  checkCookieExist()
  var currentParams = window.location.search.substring(1)
  var updatedParams = ''
  var jsonParams = {}
  if( window.location.search != ''){
    appendInput(currentParams);
  }
  
  $(".link-primary").click(function(){
    var url = this.dataset["reference"];
    if ( window.location.search != '' ) {
      window.location = setUrl(jsonParams,currentParams,updatedParams,url)
    }else{
      window.location=url+`?source=google&sid=5`
    }
  });
});

function appendInput(currentParams){
  var inputField =  getParamsJson(currentParams)
  $.each(inputField, function(key,val){
    if(key != 'source' && key != 'sid'){
      $("form").append(`<input type='hidden' name=${key} value=${val}>`)
    }
  });
}

function getParamsJson(params){
  //This regex is used to convert the url into json to get its params individually
  return JSON.parse('{"' + decodeURI(params).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
}

function setUrl(jsonParams,currentParams,updatedParams,url){
  jsonParams = getParamsJson(currentParams)
  jsonParams["source"] = getUrlParameter("source") || 'google';
  jsonParams["sid"] = getUrlParameter("sid") || '5';
  $.each(jsonParams, function(key,val){
    updatedParams += `${key}=${val}&`
  });
  var redirectURL = `${url}?${updatedParams}`
  return redirectURL 
}

function getCookie(name) {
  var dc = document.cookie;
  var prefix = name + "=";
  var begin = dc.indexOf("; " + prefix);
  if (begin == -1) {
    begin = dc.indexOf(prefix);
    if (begin != 0) return null;
  }
  else
  {
    begin += 2;
    var end = document.cookie.indexOf(";", begin);
    if (end == -1) {
      end = dc.length;
    }
  }
  return decodeURI(dc.substring(begin + prefix.length, end));
}

function checkCookieExist() {
  if (this.getCookie("klaro") != null) {
    this.myCookie = this.getCookie("klaro");
    this.myCookie = JSON.parse(decodeURIComponent(this.myCookie))['Local Storage']
  }
}

function CookieEvent(){
  dataLayer.push({'event': 'cookieconsent'})
}

function getUrlParameter(sParam) {
  var sPageURL = window.location.search.substring(1),
      sURLVariables = sPageURL.split('&'),
      sParameterName,
      i;
  for (i = 0; i < sURLVariables.length; i++) {
      sParameterName = sURLVariables[i].split('=');
    if (sParameterName[0] === sParam) {
        return sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1]);
    }
  }
}
